export const MODEL_TYPE_OBJECT = 'MODEL_TYPE_OBJECT';
export const MODEL_TYPE_NESTED_LIST = 'MODEL_TYPE_NESTED_LIST';
export const MODEL_TYPE_LIST = 'MODEL_TYPE_LIST';
export const MODEL_TYPE_DATE = 'MODEL_TYPE_DATE';

const dateType = { type: MODEL_TYPE_DATE };

const brand = { resource: 'brands', type: MODEL_TYPE_OBJECT };
const weaponType = { resource: 'weaponTypes', type: MODEL_TYPE_OBJECT };
const caliber = { resource: 'calibers', type: MODEL_TYPE_OBJECT };
const supplier = { resource: 'suppliers', type: MODEL_TYPE_OBJECT };
const shippingCompany = { resource: 'shippingCompanies', type: MODEL_TYPE_OBJECT };
const warehouse = { resource: 'warehouses', type: MODEL_TYPE_OBJECT };
const warehouseGrid = { resource: 'warehouseGrids', type: MODEL_TYPE_OBJECT };
const service = { resource: 'services', type: MODEL_TYPE_OBJECT };
const entryStatus = { resource: 'entryStatuses', type: MODEL_TYPE_OBJECT }
const organization = { resource: 'organizations', type: MODEL_TYPE_OBJECT }
const weaponPurchaseStatus = { resource: 'weaponPurchaseStatuses', type: MODEL_TYPE_OBJECT };
const weaponInventoryStatus = { resource: 'weaponInventoryStatuses', type: MODEL_TYPE_OBJECT };
const ammunitionType = { resource: 'ammunitionTypes', type: MODEL_TYPE_OBJECT };
const formTemplate = { resource: 'formTemplates', type: MODEL_TYPE_OBJECT };
const status = { resource: 'formStatus', type: MODEL_TYPE_OBJECT };

export const foldersDescriptor = {
    formTemplate,
}

const folder = {
    resource: 'folders',
    type: MODEL_TYPE_OBJECT,
    descriptor: foldersDescriptor,
}

foldersDescriptor.parent = {
    ...folder,
    resource: 'folderParents',
};

const country = { resource: 'countries', type: MODEL_TYPE_OBJECT };

export const institutionsDescriptor = {
    country
}

const institution = {
    resource: 'institutions',
    type: MODEL_TYPE_OBJECT,
    descriptor: institutionsDescriptor,
}

export const usersDescriptor = {
    PasswordRecordTimestamp: dateType,
}

const user = {
    resource: 'users',
    type: MODEL_TYPE_OBJECT,
    descriptor: usersDescriptor,
};

const role = {
    resource: 'roles',
    type: MODEL_TYPE_OBJECT
};


export const userRoleDescriptor = {
    user,
    role,
}

export const userInstitutionDescriptor = {
    user,
    institution,
};

export const folderInstitutionDescriptor = {
    folder,
    institution,
};

export const formDescriptor = {
    currentStatus: status,
}

const readOnlyCountry = {
    ...country,
    readOnly: true,
};

const readOnlyBrand = {
    ...brand,
    readOnly: true,
}

const readOnlySupplier = {
    ...supplier,
    readOnly: true,
}

const readOnlyShippingCompany = {
    ...shippingCompany,
    readOnly: true,
}

const readOnlyService = {
    ...service,
    readOnly: true,
}

const readOnlyInstitution = {
    ...institution,
    readOnly: true,
}

const readOnlyWarehouse = {
    ...warehouse,
    readOnly: true,
}

const readOnlyWarehouseGrid = {
    ...warehouseGrid,
    readOnly: true,
}

const readOnlyEntryStatus = {
    ...entryStatus,
    readOnly: true,
}

const readOnlyOrganization = {
    ...organization,
    readOnly: true,
}

export const regionDescriptor = {
    countries: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            country: readOnlyCountry
        },
    }
}

export const departmentDescriptor = {
    resource: 'departments',
    type: MODEL_TYPE_OBJECT,
    institution: readOnlyInstitution
}

export const positionDescriptor = {
    resource: 'positions',
    type: MODEL_TYPE_OBJECT,
    readOnly: true,
    model: {
        department: departmentDescriptor
    }
}

export const organizationsDescriptor = {
    organizations: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            organization: readOnlyOrganization
        },
    }
}

export const serviceProviderDescriptor = {
    addressCountry: readOnlyCountry,
};

const serviceProvider = { resource: 'serviceProviders', type: MODEL_TYPE_OBJECT, descriptor: serviceProviderDescriptor };

const serviceProviderReadOnly = {
    ...serviceProvider,
    readOnly: true,
};

export const weaponPurchaseEntryDescriptor = {
    warehouse,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const weaponPurchaseVoidEntryDescriptor = {
    voidModels: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const weaponVoluntarySurrenderEntryDescriptor = {
    warehouse,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const weaponDonationEntryDescriptor = {
    warehouse,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const weaponConfiscationEntryDescriptor = {
    warehouse,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const manufacturerDescriptor = {
    country,
};

const manufacturer = {
    resource: 'manufacturers',
    type: MODEL_TYPE_OBJECT,
    descriptor: manufacturerDescriptor,
}

const readOnlyManufacturer = {
    ...manufacturer,
    readOnly: true,
}

export const weaponModelDescriptor = {
    brand,
    weaponType,
    caliber,
};

const weaponModel = {
    resource: 'weaponModels',
    type: MODEL_TYPE_OBJECT,
    descriptor: weaponModelDescriptor,
};

const readOnlyWeaponModel = {
    ...weaponModel,
    readOnly: true,
}

const weapon = {
    resource: 'weapons',
    type: MODEL_TYPE_OBJECT,
    descriptor: {

        weaponModel,
    },
};

const readOnlyWeapon = {
    ...weapon,
    readOnly: true,
};

const readOnlyWeaponPurchaseStatus = {
    ...weaponPurchaseStatus,
    readOnly: true,
}

const readOnlyWeaponInventoryStatus = {
    ...weaponInventoryStatus,
    readOnly: true,
}

export const weaponPurchaseDescriptor = {
    purchaseDate: dateType,
    deliveryDate: dateType,

    weaponPurchaseStatus: readOnlyWeaponPurchaseStatus,
    supplier: readOnlySupplier,
    institution: readOnlyInstitution,
    shippingCompany: readOnlyShippingCompany,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            brand: readOnlyBrand,
            model: readOnlyWeaponModel,
        }
    },
    transitCountries: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            transitDate: dateType,

            country: readOnlyCountry,
        }
    },
    entries: {
        resource: 'weaponPurchaseEntries',
        type: MODEL_TYPE_LIST,
        descriptor: weaponPurchaseEntryDescriptor,
    }
};

export const weaponConfiscationDescriptor = {
    confiscationDate: dateType,

    institution: readOnlyInstitution,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            brand: readOnlyBrand,
            model: readOnlyWeaponModel,
            manufacturer: readOnlyManufacturer,
        }
    },
    entries: {
        resource: 'weaponConfiscationEntries',
        type: MODEL_TYPE_LIST,
        descriptor: weaponConfiscationEntryDescriptor,
    }
};

export const weaponVoluntarySurrenderDescriptor = {
    institution: readOnlyInstitution,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            brand: readOnlyBrand,
            model: readOnlyWeaponModel,
        }
    },
    entries: {
        resource: 'weaponVoluntarySurrenderEntries',
        type: MODEL_TYPE_LIST,
        descriptor: weaponVoluntarySurrenderEntryDescriptor,
    },
};

export const weaponDonationDescriptor = {
    institution: readOnlyInstitution,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            brand: readOnlyBrand,
            model: readOnlyWeaponModel,
        }
    },
    entries: {
        resource: 'weaponDonationEntries',
        type: MODEL_TYPE_LIST,
        descriptor: weaponDonationEntryDescriptor,
    },
};

export const weaponInternalTransferDescriptor = {
    exitDate: dateType,
    expectedDeliveryDate: dateType,

    shippingCompany: readOnlyShippingCompany,
    destinationWarehouse: readOnlyWarehouse,
    weapons: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            weapon: readOnlyWeapon,
        }
    },
    entries: weaponVoluntarySurrenderEntryDescriptor
};

export const weaponMovementDescriptor = {
    recordTimestamp: dateType,
    movementDate: dateType,

    weapons: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            weapon: readOnlyWeapon,
            destinationGrid: readOnlyWarehouseGrid,
        }
    },
    entries: weaponVoluntarySurrenderEntryDescriptor
};


export const maintenanceDescriptor = {
    maintenanceTime: dateType,
    nextMaintenanceDate: dateType,

    serviceProvider: serviceProviderReadOnly,

    services: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            service: readOnlyService,
        }
    },
    weapons: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            weapon: readOnlyWeapon,
        }
    },
};

export const weaponDestructionDescriptor = {
    weaponDestructionStartTime: dateType,
    weaponDestructionFinishTime: dateType,

    weapons: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            weapon: readOnlyWeapon,
        }
    },
    serviceProvider: serviceProviderReadOnly,
};

export const AdministrativeDivisionDescriptor = {
    country,
    parent1: {
        type: MODEL_TYPE_OBJECT,
        resource: 'administrativeDivisions',
    },
    parent2: {
        type: MODEL_TYPE_OBJECT,
        resource: 'administrativeDivisions',
    },
    parent3: {
        type: MODEL_TYPE_OBJECT,
        resource: 'administrativeDivisions',
    },
};

export const userRole = {
    user,
    role,
}

export const userWarehouse = {
    user,
    warehouse,
}

export const weaponDescriptor = {
    weaponModel,
    warehouse,
    warehouseGrid,
    inventoryStatus: readOnlyWeaponInventoryStatus,
}

export const ammunitionModelDescriptor = {
    brand,
    ammunitionType,
    caliber,
};

const ammunitionModel = {
    resource: 'ammunitionModels',
    type: MODEL_TYPE_OBJECT,
    descriptor: ammunitionModelDescriptor,
};

const readOnlyAmmunitionModel = {
    ...ammunitionModel,
    readOnly: true,
}

export const ammunitionPurchaseDescriptor = {
    purchaseDate: dateType,
    deliveryDate: dateType,

    entryStatus: readOnlyEntryStatus,
    supplier: readOnlySupplier,
    institution: readOnlyInstitution,
    shippingCompany: readOnlyShippingCompany,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            brand: readOnlyBrand,
            model: readOnlyAmmunitionModel,
        }
    },
    transitCountries: {
        type: MODEL_TYPE_NESTED_LIST,
        descriptor: {
            transitDate: dateType,

            country: readOnlyCountry,
        }
    },
    // entries: {
    //     resource: 'weaponPurchaseEntries',
    //     type: MODEL_TYPE_LIST,
    //     descriptor: weaponPurchaseEntryDescriptor,
    // }
};

export const ammunitionPurchaseEntryDescriptor = {
    arrivalTime: dateType,
    warehouse,
    models: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}

export const ammunitionPurchaseVoidEntryDescriptor = {
    voidModels: {
        type: MODEL_TYPE_NESTED_LIST,
    },
}
